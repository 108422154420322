<template>
  <div id="tender-list">
    <v-card>
      <v-card-title class="d-flex justify-space-between flex-wrap">
        <div>{{ $t('tenders.tenderList') }}</div>

        <div class="d-flex align-center flex-wrap">
          <router-link :to="{name: 'admin-tender-create'}">
            <v-btn
              color="success"
              class="mb-4 me-3"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>{{ $t('tenders.tenderCreate') }}</span>
            </v-btn>
          </router-link>
        </div>
      </v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="$t('general.typeToSearch')"
            outlined
            hide-details
            dense
            class="tender-search me-3 mb-4"
            @change="getDataFromApi"
          >
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="3"
        >
          <v-select
            v-model="statusFilter"
            :placeholder="$t('general.statusFilter')"
            :items="tenderStatuses"
            item-value="key"
            :item-text="item => $t(item.name)"
            outlined
            dense
            clearable
            hide-details
            @change="getDataFromApi"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="3"
        >
          <v-autocomplete
            v-model="companyFilter"
            dense
            outlined
            :placeholder="$t('tender.company')"
            :items="companies"
            item-text="name"
            item-value="id"
            clearable
            type="search"
            @update:search-input="filterCompanies"
            @change="getDataFromApi"
          ></v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          sm="3"
        >
          <v-select
            v-model="categoryFilter"
            :placeholder="$t('general.categoryFilter')"
            :items="tenderCategories"
            :item-text="item => $t(item.name)"
            item-value="key"
            outlined
            dense
            clearable
            hide-details
            @change="getDataFromApi"
          ></v-select>
        </v-col>
      </v-row>

      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="tenderListTable"
        :items-per-page="15"
        :options.sync="options"
        :server-items-length="totalTenders"
        :loading="loading"
      >
        <template #[`item.platform_reference_number`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'admin-tender-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.platform_reference_number }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.client_reference_number`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'admin-tender-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.client_reference_number }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.title`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'admin-tender-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.title }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.tender_date`]="{item}">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.tender_date }}</span>
          </div>
        </template>

        <template #[`item.status`]="{item}">
          <v-chip
            small
            class="v-chip-light-bg font-weight-semibold text-capitalize"
            :color="resolveStatusVariant(item.status)"
            :class="`${resolveStatusVariant(item.status)}--text`"
          >
            {{ $t(item.status) }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'admin-tender-view',params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                :to="{name:'admin-tender-edit',params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentEditOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="!item.deleted_at"
                link
              >
                <v-list-item-title @click.prevent="deleteTender(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.deleted_at"
                link
              >
                <v-list-item-title @click.prevent="restoreTender(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileRestoreOutline }}
                  </v-icon>
                  <span>{{ $t('general.restore') }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentEditOutline,
  mdiFileDocumentOutline,
  mdiFileRestoreOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'

// sidebar
import axiosIns from '@axios'

export default {
  components: {
  },
  data() {
    const selectedRows = []
    const tenderListTable = []
    const totalTenders = 0
    const loading = true
    const options = {}

    const resolveStatusVariant = status => {
      if (status === 'draft') return 'primary'
      if (status === 'active') return 'success'
      if (status === 'inactive' || status === 'semiinactive') return 'secondary'
      if (status === 'archived') return 'default'

      return 'primary'
    }

    const tableColumns = [
      { text: 'TITLE', value: 'title' },
      { text: 'PLATFORM REFERENCE NUMBER', value: 'platform_reference_number' },
      { text: 'CLIENT REFERENCE NUMBER', value: 'client_reference_number' },
      { text: 'DATE', value: 'tender_date' },
      { text: 'STATUS', value: 'status' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    const companyFilter = ''

    const companies = []

    const categoryFilter = ''

    const { tenderCategories } = this.$store.state

    const statusFilter = ''
    const { tenderStatuses } = this.$store.state

    const searchQuery = ''

    return {
      options,
      loading,
      categoryFilter,
      companyFilter,
      searchQuery,
      statusFilter,
      tableColumns,
      selectedRows,
      tenderListTable,
      totalTenders,
      companies,
      tenderCategories,
      tenderStatuses,
      resolveStatusVariant,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiFileDocumentEditOutline,
        mdiFileRestoreOutline,
      },
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    filterCompanies(filter) {
      if (filter && filter.length >= 2) {
        axiosIns.get(`/admin/companies?filter=${filter}`).then(res => {
          this.companies = res.data.companies.data
        }).catch()
      }
    },
    getDataFromApi() {
      this.loading = true
      const orderBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : ''
      const orderDirection = this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : ''
      const search = this.searchQuery ?? ''
      const company = this.companyFilter ?? ''
      const status = this.statusFilter ?? ''
      const category = this.categoryFilter ?? ''
      axiosIns.get(`/admin/tenders?page=${this.options.page}&perPage=
      ${this.options.itemsPerPage}&orderBy=${orderBy}&orderDirection=${orderDirection}&search=${search}&
      company_id=${company}&status=${status}&category=${category}`)
        .then(res => {
          this.totalTenders = res.data.tenders.total
          this.tenderListTable = res.data.tenders.data
          this.loading = false
        })
        .catch()
    },
    deleteTender(id) {
      axiosIns.delete(`admin/tenders/${id}`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch()
    },
    restoreTender(id) {
      axiosIns.put(`/admin/tenders/${id}/restore`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch()
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
